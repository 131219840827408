import React from 'react'

const Spinner = ({clase}) => (
    <div id="main-spinner" className={clase}>
        <div className="fondo"></div>
        <svg
            id="prefix__Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x={0}
            y={0}
            viewBox="0 0 273.1 212.2"
            xmlSpace="preserve"
            >
            <style>{".prefix__st0{fill:#fff}"}</style>
            <path
                className="prefix__st0"
                d="M0 0v212.2h273.1V0H0zm227.2 177.4c-18.8 16.5-38.3 24.8-58.6 24.8-13.8 0-25.2-4.8-34.2-14.4-8.9-9.6-13.4-22-13.4-37.2 0-1.9.1-3.8.3-5.8-6.6 13-14.1 27.4-15.5 29.4-10.1 14.8-23 23.7-38.5 26.7-14.3 2.7-26.5.9-36.7-5.7-10.2-6.5-16.7-16.6-19.3-30.2-4.4-23 3.3-48.9 23.2-77.7 16.3-23.8 35.2-37.8 56.7-41.9 8.1-1.6 15.1-.6 21 2.9s9.6 8.9 11 16.1c2.1 10.7-1.4 17-10.4 18.7-3.1.6-6 0-8.9-1.9-2.9-1.8-4.6-4.2-5.1-7-1-5.3 1.4-8.5 7.4-9.6l2.7-.2c1.7.4 3.1.5 4.2.6 1.8-.3 2.4-1.7 1.9-4.1-.7-3.5-3.4-6.2-8.2-8.1-4.8-1.9-9.8-2.4-15.1-1.4-16.2 3.1-29.6 18.2-40.1 45.2-10.8 27.7-14.1 52.3-10 73.7 3.5 18.4 13.6 26 30.3 22.7 10.4-2.7 23.7-12.3 33.5-29.7.5-.9 20.6-40.6 22.3-43.6 1.3-3 2.7-6.1 4.4-9.2 7.4-13.6 17.2-25.2 29.5-34.8 19.2-15 37.7-22.4 55.6-22.4 13.6 0 24.7 4.9 33.2 14.7 8.5 9.8 12.7 22.7 12.7 38.5 0 26.4-12 49.9-35.9 70.9zm35.9-162.7c-.2.8-1 2-1 2-7 12.9-14.4 19.1-14.4 19.1-6.9 6.3-13.8 9.4-20.8 9.4-4.2 0-7.9-.7-10.9-2s-9.3-5.4-18.9-12.1c-7.8-5.4-14.1-8-18.8-8-2.5 0-4.6 1.1-6.5 3.3-1.9 2.2-2.8 4.8-2.8 7.7 0 1.6.6 3.1 1.8 4.5 1.2 1.5 1.8 2.4 1.8 2.8 0 1.6-.9 2.3-2.7 2.3-1.6 0-3-1.1-4.2-3.2-1.2-2.1-1.8-4.4-1.8-6.9 0-5.8 3.4-11.2 10.2-16.2 6.8-5 14.1-7.5 21.9-7.5 5.1 0 9.4.7 12.7 2.2 3.3 1.5 9.2 5.1 17.4 10.9 6.9 4.9 12.2 7.4 15.7 7.4 3.1 0 6-1.7 8.7-5 1.8-3.1 3.7-6.1 5.7-9 1.6-2.2 3-3.3 4.4-3.3 1.7-.1 2.8.5 2.5 1.6z"
            />
            <path
                className="prefix__st0"
                d="M214.5 59.5c-18.5 0-34.6 13.3-48.2 39.9-13 25.5-19.4 49.4-19.4 71.7 0 16.7 7.5 25.1 22.4 25.1 18.8 0 34.4-12.1 46.9-36.2 14.1-27.2 21.1-52.3 21.1-75 0-17-7.6-25.5-22.8-25.5z"
            />
            <path d="M136.5 106.7c0-.1.1-.2.1-.2.1-.4.1-.7-.1-.9v1.1z" />
        </svg>
    </div>
);

export default Spinner;