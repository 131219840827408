import React, {Fragment,useState} from 'react'
import Spinner from './Spinner';
import Alerta from './Alerta';

const EditarPublicacion = ({
        id,
        options,
        categoria,
        nombre_categoria,
        generos,
        nombres_generos,
        titulo,
        sinopsis,
        publicacion_mostrada,
        updatePublicacionGeneral,
        updateEdicion}) => {
    let user_data = JSON.parse(localStorage.getItem('co_user_data'));
    const[processing, updateProcessing] = useState(false);
    const[publicacion, updatePublicacion] = useState({
        'categoria_id': categoria,
        'categoria': nombre_categoria,
        'generos_id': generos,
        'generos': nombres_generos,
        'titulo': titulo,
        'sinopsis': sinopsis
    })
    const selectorCategoria = (e) => {
        updatePublicacion({
            ...publicacion,
            'categoria_id':e.target.dataset.categoria,
            'categoria': e.target.textContent
        })
    }

    const[mostrarPopup, showPopup] = useState(false);
    const[mensaje, updateMensaje] = useState(false);

    const selectorGenero = (e) => {
        if(publicacion.generos_id.indexOf(e.target.dataset.genero) > -1){
            let generos_seleccionados = publicacion.generos_id.filter(genero => genero !== e.target.dataset.genero);
            updatePublicacion({
                ...publicacion,
                'generos_id':generos_seleccionados
            })
        }else{
            let generos_seleccionados = publicacion.generos_id;
            generos_seleccionados.push(e.target.dataset.genero);
            updatePublicacion({
                ...publicacion,
                'generos_id':generos_seleccionados
            })
        }
    }

    const updateFields = (e) => {
        updatePublicacion({
            ...publicacion,
            [e.target.name]: e.target.value
        })
    }

    const guardarPublicacion = async () => {
        if(publicacion.categoria === 0 || publicacion.generos_id.length < 1 || publicacion.titulo.trim() === ''){
            updateMensaje('Todos los campos son obligatorios.');
            showPopup(true);
            return;
        }
        updateProcessing(true);

        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('obra_id',id);
        formdata.append('categoria',publicacion.categoria_id);
        formdata.append('generos',publicacion.generos_id.join(','));
        formdata.append('titulo',publicacion.titulo);
        formdata.append('sinopsis',publicacion.sinopsis);
        const api = await fetch(
            'https://www.devbrapp.editorialco.com/api/autor/actualizarpublicacion/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );

        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            updateProcessing(false);
            return;
        }

        const response = await api.json();
        if(!response.success){
            updateProcessing(false);
            updateMensaje(response.mensaje);
            showPopup(true);
            return;
        }
        let generos_guardados = [];
        publicacion.generos_id.forEach(function (item, index) {
            generos_guardados.push(document.getElementById("genero-"+item).textContent.trim());
          });

        console.log(generos_guardados)

        updatePublicacionGeneral({
            ...publicacion_mostrada,
            'titulo': publicacion.titulo,
            'sinopsis': publicacion.sinopsis,
            'categoria_id': publicacion.categoria_id,
            'categoria': publicacion.categoria,
            'generos_id':publicacion.generos_id.join(','),
            'generos': generos_guardados.join(', ')
        })

        updateEdicion(false)
    }

    const ocultarPopup = () => {
        showPopup(false)
    }

    return (
        <div id="datos-publicacion">
            <Alerta
                mensaje={mensaje}
                mostrarPopup={mostrarPopup}
                ocultar={ocultarPopup}
            />
            <div className="container">
                <div id="publicacion-form">
                    <div className="field-container">
                        <div className="field-description">
                            <p>
                                En esta sección comienza la publicación de tu primer libro. 
                                Aquí, podrás crear un volumen de varios cuentos, o una novela con muchos capítulos. 
                            </p>
                            Para comenzar elige una categoría.
                        </div>
                        <div className="field-values">
                            { options.categorias !== undefined ?
                                options.categorias.map(categoria_option => (
                                    <button
                                        className={"option option-cetegoria"+(publicacion.categoria_id == categoria_option.id ? ' selected' : '')}
                                        data-categoria={categoria_option.id}
                                        key={"categoria-"+categoria_option.id}
                                        onClick={selectorCategoria}
                                    >
                                        {categoria_option.nombre}
                                    </button>
                                )):
                                null
                            }
                            {
                                publicacion.categoria_id == 1 ?
                                    <div id="descripcion-categoria">
                                        Si seleccionas esta categoría, piensa que estarás haciendo un libro de cuentos. 
                                        Dentro, podrás ir creando nuevas secciones para cada cuento y podrás ponerles 
                                        los títulos a cada uno.
                                    </div>
                                :
                                    null
                            }
                            {
                                publicacion.categoria_id == 2 ?
                                <div id="descripcion-categoria">
                                    La novela está dividida por varios capítulos. Dentro podrás ir separándola como tal, 
                                    y nombrando cada capítulo.
                                </div>
                                :
                                    null
                            }
                        </div>
                    </div>
                    <div className="field-container">
                        <div className="field-description">¿A qué género(s) pertenece?, puedes seleccionar una o varias.</div>
                        <div className="field-values">
                            { options.generos !== undefined ?
                                options.generos.map(genero => (
                                    <button
                                        className={"option option-genero"+ (publicacion.generos_id.indexOf(genero.id+'') > -1 ? ' selected' : '')}
                                        id={"genero-"+genero.id}
                                        data-genero={genero.id}
                                        key={"genero"+genero.id}
                                        onClick={selectorGenero}
                                    >
                                        {genero.nombre}
                                    </button>
                                )):
                                null
                            }
                        </div>
                    </div>
                    <div className="field-container">
                        <div className="field-values">
                            <input
                                value={ publicacion.titulo }
                                type="text"
                                name="titulo" 
                                placeholder="Ingresa el nombre de tu obra (Título)"
                                onChange={updateFields}
                            />
                        </div>
                    </div>
                    <div className="field-container">
                        <div className="field-values">
                            <textarea
                                name="sinopsis"
                                rows="5"
                                placeholder="Escribe la sinopsis de tu obra. Si no la tienes aún, más adelante puedes editarla."
                                onChange={updateFields}
                                defaultValue={ sinopsis }></textarea>
                        </div>
                    </div>
                </div>
                { processing ?
                    <Spinner clase="spinner-small"></Spinner> : 
                    null
                }
                { processing ? 
                    null :
                    <div id="contenedor-acciones">
                        <button
                            className="btn"
                            onClick={() => {updateEdicion(false)}}
                        >
                            Cancelar
                        </button>    
                        <button
                            className="btn btn-co"
                            id="guardar-publicacion"
                            onClick={guardarPublicacion}
                        >
                            Guardar
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

export default EditarPublicacion;