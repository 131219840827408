import React, {useState, useEffect, Fragment} from 'react'
import { useHistory } from 'react-router'
import Spinner from './Spinner';
import ReactGA from 'react-ga';
import Alerta from './Alerta';

const AgregarPublicacion = () => {
    let history = useHistory();
    let user_data = JSON.parse(localStorage.getItem('co_user_data'));
    const[publicacion,updatePublicacion] = useState({
        categoria: 0,
        generos: [],
        titulo: '',
        sinopsis: ''
    })

    const[options,updateOptions] = useState({})
    const[processing, updateProcessing] = useState(false);
    const[firstLoad, updateFirstLoad] = useState(true);
    const[mostrarPopup, showPopup] = useState(false);
    const[mensaje, updateMensaje] = useState(false);

    useEffect( () => {
        ReactGA.initialize('GTM-5JDTRDH');
        ReactGA.pageview(window.location.pathname + window.location.search);
        getOptions();
    },[]);

    const selectorCategoria = (e) => {
        updatePublicacion({
            ...publicacion,
            'categoria':e.target.dataset.categoria
        })
    }

    const selectorGenero = (e) => {
        if(publicacion.generos.indexOf(parseInt(e.target.dataset.genero)) > -1){
            let generos = publicacion.generos.filter(genero => genero !== parseInt(e.target.dataset.genero));
            updatePublicacion({
                ...publicacion,
                'generos':generos
            })
        }else{
            let generos = publicacion.generos;
            generos.push(parseInt(e.target.dataset.genero));
            updatePublicacion({
                ...publicacion,
                'generos':generos
            })
        }
    }

    const updateFields = (e) => {
        updatePublicacion({
            ...publicacion,
            [e.target.name]: e.target.value
        })
    }

    const getOptions = async () => {
        const api = await fetch(
            'https://www.devbrapp.editorialco.com/api/autor/opcionespublicacion/',
            {
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            updateMensaje(response.mensaje);
            showPopup(true);
            return;
        }
        updateOptions(response);
        updateFirstLoad(false);
    }

    const guardarPublicacion = async () => {
        if(publicacion.categoria === 0 || publicacion.generos.length < 1 || publicacion.titulo.trim() === ''){
            updateMensaje('Todos los campos son obligatorios.');
            showPopup(true);
            return;
        }
        updateProcessing(true);

        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('categoria',publicacion.categoria);
        formdata.append('generos',publicacion.generos.join(','));
        formdata.append('titulo',publicacion.titulo);
        formdata.append('sinopsis',publicacion.sinopsis);

        const api = await fetch(
            'https://www.devbrapp.editorialco.com/api/autor/guardarpublicacion/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );

        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            return;
        }

        const response = await api.json();
        if(!response.success){
            updateProcessing(false)
            updateMensaje(response.mensaje);
            showPopup(true);
            return;
        }
        updateMensaje(response.mensaje);
        showPopup(true);
        updateProcessing(false);
        history.push("/publicaciones/ver/"+response.obra.id);
    }

    const ocultarPopup = () => {
        showPopup(false)
    }

    return (
        <div id="datos-publicacion">
            <div className="container">
                {
                    firstLoad ?
                        <Spinner></Spinner>
                    :
                    <Fragment>
                        {
                            mostrarPopup ? 
                                <Alerta
                                    mensaje={mensaje}
                                    mostrarPopup={true}
                                    ocultar={ocultarPopup}
                                />
                            :
                            null
                        }
                        <div id="publicacion-form">
                            <div className="field-container">
                                <div className="field-description">
                                    <p>
                                        En esta sección comienza la publicación de tu primer libro. 
                                        Aquí, podrás crear un volumen de varios cuentos, o una novela con muchos capítulos. 
                                    </p>
                                    Para comenzar elige una categoría.
                                </div>
                                <div className="field-values">
                                    { options.categorias !== undefined ?
                                        options.categorias.map(categoria => (
                                            <button
                                                className={"option option-categoria"+(publicacion.categoria == categoria.id ? ' selected' : '')}
                                                data-categoria={categoria.id}
                                                key={"categoria-"+categoria.id}
                                                onClick={selectorCategoria}
                                            >
                                                {categoria.nombre}
                                            </button>
                                        )):
                                        null
                                    }
                                    {
                                        publicacion.categoria == 1 ?
                                            <div id="descripcion-categoria">
                                                Si seleccionas esta categoría, piensa que estarás haciendo un libro de cuentos. 
                                                Dentro, podrás ir creando nuevas secciones para cada cuento y podrás ponerles 
                                                los títulos a cada uno.
                                            </div>
                                        :
                                            null
                                    }
                                    {
                                        publicacion.categoria == 2 ?
                                        <div id="descripcion-categoria">
                                            La novela está dividida por varios capítulos. Dentro podrás ir separándola como tal, 
                                            y nombrando cada capítulo.
                                        </div>
                                        :
                                            null
                                    }
                                </div>
                            </div>
                            <div className="field-container">
                                <div className="field-description">¿A qué género(s) pertenece?, puedes seleccionar una o varias.</div>
                                <div className="field-values">
                                    { options.generos !== undefined ?
                                        options.generos.map(genero => (
                                            <button
                                                className={"option option-genero"+ (publicacion.generos.indexOf(genero.id) > -1 ? ' selected' : '')}
                                                data-genero={genero.id}
                                                key={"genero"+genero.id}
                                                onClick={selectorGenero}
                                            >
                                                {genero.nombre}
                                            </button>
                                        )):
                                        null
                                    }
                                </div>
                            </div>
                            <div className="field-container">
                                <div className="field-values">
                                    <input
                                        type="text"
                                        name="titulo" 
                                        placeholder="Ingresa el nombre de tu obra (Título)"
                                        onChange={updateFields}
                                    />
                                </div>
                            </div>
                            <div className="field-container">
                                <div className="field-values">
                                    <textarea
                                        name="sinopsis"
                                        rows="5"
                                        placeholder="Escribe la sinopsis de tu obra. Si no la tienes aún, más adelante puedes editarla."
                                        onChange={updateFields}></textarea>
                                </div>
                            </div>
                        </div>
                        { processing ?
                            <Spinner clase="spinner-small"></Spinner> : 
                            null
                        }
                        { processing ? 
                            null : 
                            <button
                                className="btn btn-co"
                                id="guardar-publicacion"
                                onClick={guardarPublicacion}
                            >
                                Guardar
                            </button>
                        }
                    </Fragment>
                }
            </div>
        </div>
    )
}

export default AgregarPublicacion;