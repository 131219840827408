import React, {useState, Fragment} from 'react';
import { BrowserRouter as Router, NavLink, Link, Switch, Route } from 'react-router-dom';
import { useHistory } from 'react-router'
import Login from './components/Login';
import Publicaciones from './components/Publicaciones';
import Ventas from './components/Ventas';
import Perfil from './components/Perfil';
import Registro from './components/Registro';
import './css/global.css'

function App() {
  let history = useHistory();
  let user_data = JSON.parse(localStorage.getItem('co_user_data'));
  if(!user_data){
    user_data = {};
  }

  //User
  const [user, userUpdate] = useState(user_data);

  const loginUser = (loggedinUser) => {
    userUpdate(loggedinUser)
    localStorage.setItem('co_user_data',JSON.stringify(loggedinUser));
    setTimeout(() => {
      if(loggedinUser.primer_login == '1'){
        localStorage.setItem('co_primer_login',1);
      }
      history.push("/publicaciones");
    }, 1000)
  }

  const logoutUser = () => {
    userUpdate({})
    localStorage.setItem('co_user_data',JSON.stringify({}))
    history.push("/");
  }

  if(user.auth_key === undefined){
    if(window.location.pathname == '/registro'){
      return(
        <Fragment>
          <header id="main-header">
              <div className="container">
                  <div id="logo">
                      <a href="https://www.editorialco.com">
                        <img
                            src={"/img/logo.png"}
                            alt="Logo Editorial Cõ"
                            title="Logo Editorial Cõ"
                        />
                      </a>
                  </div>
                  <div id="main-menu">
                  </div>
              </div>
          </header>
          <Registro loginUser = {loginUser}/>
          <a href="https://m.me/106563867711567" target="_blank" id="messenger">
            <img
                className="messenger"
                src={"/img/messenger.png"}
                alt="Messenger"
                title="Messenger"
            />
          </a>
        </Fragment>
      )
    }else{
        return (
          <Fragment>
            <Login 
              loginUser = {loginUser}
            />
            <a href="https://m.me/106563867711567" target="_blank" id="messenger">
            <img
                className="messenger"
                src={"/img/messenger.png"}
                alt="Messenger"
                title="Messenger"
            />
          </a>
        </Fragment>
        );
    }
  }else{
    return (
      <Fragment>
        <header id="main-header">
            <div className="container">
                <div id="logo">
                    <Link to={'/publicaciones'}>
                      <img
                          src={"/img/logo.png"}
                          alt="Logo Editorial Cõ"
                          title="Logo Editorial Cõ"
                      />
                    </Link>
                </div>
                <div id="main-menu">
                    <ul>
                        <li>
                            <NavLink to={'/publicaciones'} activeClassName="active">Escritorio</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/ventas'} activeClassName="active">Ventas</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/perfil'} activeClassName="active">Perfil</NavLink>
                        </li>
                        <li>
                            <button onClick={logoutUser}>
                                <img
                                    src={"/img/logout.png"}
                                    alt="Cerrar Sesión"
                                    title="Cerrar Sesión"
                                />
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
        <Switch>
          <Route path='/publicaciones' component={Publicaciones} />
          <Route path='/ventas' component={Ventas} />
          <Route path='/perfil' component={Perfil} />
          <Route path='/registro' component={Registro} />
        </Switch>
        <a href="https://m.me/106563867711567" target="_blank" id="messenger">
          <img
              className="messenger"
              src={"/img/messenger.png"}
              alt="Messenger"
              title="Messenger"
          />
        </a>
      </Fragment>
    );
  }
}

export default App;
