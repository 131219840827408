import React from 'react'
import Dialog from '@material-ui/core/Dialog';

const Alerta  = ({mensaje, mostrarPopup, ocultar}) => {
    return(
        <Dialog
            maxWidth="md"
            onClose={ocultar}
            open={mostrarPopup}>
            <div className="contenido-dialogo">
                <div id="texto-confirmacion">
                    { mensaje }
                    <div id="botones-confirmacion">
                        <button id="ver-preview" className="btn btn-co" onClick={ocultar} >Aceptar</button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default Alerta;